import "./ButtonInscription.css";
import { LiaSpinnerSolid } from "react-icons/lia";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import {
  addSubProcessSectionIds,
  removeSubProcessSectionIds,
  selectInscribedSubjects,
  selectSubcriptionProcessSectionIds,
  selectSubjectsQuotas,
} from "../../reduxSlices/subjectsSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setConfirmChangeSectionData, setSectionToChange } from "../../reduxSlices/globalFlagsSlice";
import { useAppDispatch } from "../../app/store";
import { IOfferedSubjectData } from "../../types";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { reserveSection } from "../../Api/reservation";
import { useToastError } from "../../hooks/useToast";

interface BtnProps {
  id: number;
  idMateria: number;
  subjectData: IOfferedSubjectData;
}

export default function ButtonInscription({ id, idMateria, subjectData }: BtnProps) {
  const dispatch = useAppDispatch();
  const showError = useToastError();
  /* ------------------------------ global store ------------------------------ */
  const student = useSelector(selectStudent);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const subcriptionProcessSectionIds = useSelector(selectSubcriptionProcessSectionIds);
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  /* ------------------------------- local state ------------------------------ */
  const [isSubInProcess, setIsSubInProcess] = useState(false);
  /* ------------------------------ calculations ------------------------------ */
  const materiasList = inscribedSubjects?.map((s) => s.id) || [];
  const cambioSeccion = materiasList.indexOf(idMateria) > -1;
  const quota = subjectsQuotas[id];
  const { availability, qty } = quota;

  /* -------------------------------- Effeccts -------------------------------- */
  useEffect(() => {
    const isProcessing = subcriptionProcessSectionIds.indexOf(Number(id)) !== -1;
    if (isProcessing) {
      setIsSubInProcess(true);
    } else {
      setIsSubInProcess(false);
    }
  }, [subcriptionProcessSectionIds]);

  /* -------------------------------- handlers -------------------------------- */

  const handleChangeGlobalData = () => {
    dispatch(setSectionToChange({ id: Number(id), idMateria: Number(idMateria) }));
    dispatch(setConfirmChangeSectionData(subjectData));
  };

  const handleTryReserve = async (idCurso: number) => {
    dispatch(addSubProcessSectionIds(idCurso));
    const result = await reserveSection({
      idC: subjectData.reserveData.idC,
      idS: subjectData.reserveData.idS,
      idA: subjectData.reserveData.idA,
      tim: subjectData.reserveData.tim,
      tic: subjectData.reserveData.tic,
      idPersona: student.id.toString(),
    });
    if (result.data.responseCode !== 200) {
      showError(result.data.responseMessage);
      dispatch(removeSubProcessSectionIds(idCurso));
    }
  };

  if(!student.prioridad){
    return null
  }

  if (availability <= 0) {
    return (
      <button className="desktop-action-add-noquota" disabled>
        <SlIcon name="x"></SlIcon>
        Sin cupo
      </button>
    );
  }

  return (
    <button
      style={{ width: `${cambioSeccion ? "165px" : ""}` }}
      className={`desktop-action-add ${isSubInProcess ? "desktop-action-add-disabled" : ""}`}
      onClick={cambioSeccion ? () => handleChangeGlobalData() : () => handleTryReserve(id)}
      disabled={isSubInProcess}
    >
      {isSubInProcess ? (
        <>
          <LiaSpinnerSolid className="spinner-animation" /> Procesando
        </>
      ) : (
        <>
          {cambioSeccion ? (
            <>
              <SlIcon name="arrow-left-right"></SlIcon>
              Cambiar sección
            </>
          ) : (
            <>
              <SlIcon name="plus"></SlIcon>
              Inscribirme
            </>
          )}
        </>
      )}
    </button>
  );
}
