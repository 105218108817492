import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { useEffect, useRef, useState } from "react";
import "./DesktopSelectedSubjects.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectInscribedSubjects,
  selectOfferedSubjects,
  setDetailSubjectOffered,
} from "../../reduxSlices/subjectsSlice";
import { IInscribedSubject } from "../../types";
import EmptyCourses from "../EmptyCourses/EmptyCourses";
import { selectFlags, setPanelFilters } from "../../reduxSlices/globalFlagsSlice";
import BtnUnsuscribeSubject from "../BtnUnsuscribeSubject/BtnUnsuscribeSubject";
import { findProfeTitular } from "../../utils";

const DesktopSelectedSubjects = () => {
  const dispatch = useDispatch();
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const offeredSubjects = useSelector(selectOfferedSubjects);
  const { lastOfertaUpdateTime } = useSelector(selectFlags);

  const containerRef = useRef<HTMLDivElement>(null);
  const [totalCreditos, setTotalcreditos] = useState(0);

  useEffect(() => {
    setTotalcreditos(inscribedSubjects?.reduce((acc, current) => acc + current.sections[0].creditos, 0) || 0);
    console.log("Componente: LISTA DE INSCRIPTAS -> Actualizado 🔄");
  }, [inscribedSubjects, lastOfertaUpdateTime]);
  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleShowSubjectoInfo = (subjectId: number, cursoId: number) => {
    dispatch(setPanelFilters(false));
    dispatch(setDetailSubjectOffered({ subjectId, cursoId }));
  };

  return (
    <div className="desktop-inscribed-subjects" ref={containerRef}>
      <div className="desktop-inscribed-subjects-list">
        {inscribedSubjects && inscribedSubjects?.length > 0 ? (
          <>
            {lastOfertaUpdateTime !== null &&
              inscribedSubjects.map((s, index) => {
                const isLastItem = inscribedSubjects.length === index + 1;
                const buscadoEnOferta = offeredSubjects
                  ?.find((subject) => Number(subject.id) === s.id)
                  ?.sections.find((section) => section.id === s.idCurso);
                const profe = findProfeTitular(buscadoEnOferta?.subjectData);
                const observacion = buscadoEnOferta?.obs || "";
                return (
                  <InscribedSubjectCard
                    key={s.title + s.idCurso}
                    s={s}
                    observacion={observacion}
                    isLastItem={isLastItem}
                    profe={profe}
                    handleShowSubjectoInfo={handleShowSubjectoInfo}
                  />
                );
              })}
            {totalCreditos > 0 && (
              <div className="total-creditos-abogacia">
                <p className="">Total de créditos:</p>
                <h6>{totalCreditos}</h6>
              </div>
            )}
          </>
        ) : (
          <EmptyCourses />
        )}
      </div>
    </div>
  );
};

interface InscribedCardProps {
  s: IInscribedSubject;
  isLastItem: boolean;
  handleShowSubjectoInfo: (id: number, idCurso: number) => void;
  profe: string;
  observacion: string;
}

function InscribedSubjectCard({ s, isLastItem, handleShowSubjectoInfo, profe, observacion }: InscribedCardProps) {
  console.log(s);
  const alertaHorarios = s.sections.find((section) => section.subjectData.desde === null || section.subjectData.desde === null);
  return (
    <div className="desktop-item-wrapper" style={!isLastItem ? { borderBottom: "0.5px solid #eeeeee" } : {}}>
      <div className="desktop-inscribed-subject-item">
        <div className="desktop-selected-subjects-item-left">
          <h2
            className="desktop-selected-subject-item-left-title"
            onClick={() => handleShowSubjectoInfo(Number(s.id), Number(s.idCurso))}
          >
            <strong>{s.title.includes("-") ? s.title.split("-")[0] + " - " : s.title}</strong>
            {s.title.includes("-") ? s.title.split("-")[1] : ""}
          </h2>
          <p>{profe || "---"}</p>
        </div>
        <div className="desktop-selected-subject-item-right">
          <BtnUnsuscribeSubject subject={s} />
        </div>
      </div>
      {alertaHorarios && (
        <div className="flex">
          <SlIcon name="exclamation-triangle" style={{ paddingRight: "5px", fontSize: "21px" }}></SlIcon>
          <p className="text-[#B7B7B7]">
          <small>Existen horarios sin designar.</small>
          </p>
        </div>
      )}
      <div className="selected-subject-obs">
        {observacion.length > 0 && (
          <>
            <SlIcon name="exclamation-circle" style={{ paddingRight: "5px", fontSize: "21px" }}></SlIcon>
            <p className="text-[#B7B7B7]">{observacion}</p>
          </>
        )}

        {s.sections[0].creditos > 0 && (
          <p className="subject-cant-creditos">
            Créditos: <span>{s.sections[0].creditos}</span>
          </p>
        )}
      </div>
    </div>
  );
}

export default DesktopSelectedSubjects;
