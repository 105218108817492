import { parseHours, transformHorariosData } from "../../utils";
import { IOfferedSubjectData, IOfferedSubjectSection } from "../../types";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { useSelector } from "react-redux";
import "./ProfessorAndSubjectHours.css";
import { selectMobileFeatures } from "../../reduxSlices/globalFlagsSlice";
interface Props {
  subjectData: IOfferedSubjectSection["subjectData"];
  itemClass?: string;
  titleClass?: string;
  showBorderTop?: boolean;
  showRol?: boolean;
  allProfesVisible?: boolean;
  isDrawerContent: boolean;
}

const findProfeTitularForCard = (subjectData: IOfferedSubjectData) => {
  let profe;
  subjectData.subjectsByType.forEach((cursada) => {
    cursada.subjectInfo.forEach((horario) => {
      if (horario.values.rol === "Profesor") {
        profe = horario.values.profesores[0];
      }
    });
  });
  return profe ? profe : "A designar";
};

function ProfessorAndSubjectHours({
  subjectData,
  showBorderTop = false,
  showRol = false,
  itemClass,
  allProfesVisible = true,
  isDrawerContent,
}: Props) {
  const student = useSelector(selectStudent);
  const mobileFeatures = useSelector(selectMobileFeatures);

  const getProfesorLastname = (completeName: string) => {
    const lastname = completeName.split(",")[0];
    return lastname || completeName;
  };

  /* ------------------- different horarios in drawer panel ------------------- */
  if (isDrawerContent) {
    return (
      <>
        {mobileFeatures ? (
          <div className={`py-2 space-y-2 border-b-[1px] border-[#eeeeee]`}>
            <div className="space-y-1">
              {subjectData.subjectsByType &&
                transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
                  const renderProfesor = tipo.titulo === "Profesor";
                  const renderHorarios = !renderProfesor;
                  const mostrarHorarios = student.mostrarHorarios;
                  if (tipo.titulo === null) {
                    return (
                      <p key={Math.random() + index} className="font-semibold text-[#B7B7B7]">
                        ⚠ Información faltante.
                      </p>
                    );
                  } else if (renderHorarios && mostrarHorarios) {
                    return (
                      <div key={Math.random() + index} className="grid grid-cols-4 text-[14px]">
                        <h4 className="col-span-3 text-[#B7B7B7] text-[16px] pb-1">{tipo.titulo}</h4>
                        <h4 className="text-[#B7B7B7] text-[16px] pb-1">Aula</h4>

                        {tipo.titulo !== null &&
                          tipo.detalles.map((horario: any, ind: number) => (
                            <>
                              <p key={Math.random() + ind} className="col-span-3 pt-1">
                                {horario?.dia} {parseHours(horario.desde)}h a {parseHours(horario.hasta)}h
                              </p>
                              <p className="pt-1">{horario.aula ? horario.aula : "Sin asignar"}</p>
                            </>
                          ))}
                      </div>
                    );
                  }
                })}
            </div>

            <div>
              <div className="bg-[#eeeeee] h-[1px] my-2"></div>
              {subjectData.subjectsByType &&
                transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
                  const renderProfesor = tipo.titulo === "Profesor";
                  if (renderProfesor) {
                    return (
                      <div key={Math.random() + index}>
                        <h4 className="text-[#B7B7B7] text-[16px]">Profesor/a</h4>

                        {tipo.detalles.length > 0 ? (
                          tipo.detalles.map((persona: any, i: number) => {
                            return (
                              <p key={Math.random() + i} className="mb-2">
                                {persona.nombre.includes("null") ? "A designar" : persona.nombre}
                                {persona.rol && !persona.rol.includes("null") && showRol && (
                                  <span className="ph-professor-rol-pill flex-nowrap inline-block">{persona.rol}</span>
                                )}
                              </p>
                            );
                          })
                        ) : (
                          <p>A designar</p>
                        )}
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        ) : (
          <div className={`content-rows-ph row-ph`} id="horarios-in-panel-drawer">
            <div className="col-horarios">
              {subjectData.subjectsByType &&
                transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
                  const renderProfesor = tipo.titulo === "Profesor";
                  const renderHorarios = !renderProfesor;
                  const mostrarHorarios = student.mostrarHorarios;
                  if (tipo.titulo === null) {
                    return <p className="font-semibold text-[#B7B7B7]">⚠ Información faltante.</p>;
                  } else if (renderHorarios && mostrarHorarios) {
                    return (
                      <div className={`ph-horarios-inner-col ph-horarios-inner-col-mobile`} key={Math.random() + index}>
                        <div className="row-titulos-horario" style={{ display: "flex" }}>
                          <h4 style={{ width: "70%", marginRight: "10px" }}>{tipo.titulo}</h4>
                          <h4>Aula</h4>
                        </div>
                        {tipo.titulo !== null &&
                          tipo.detalles.map((horario: any, ind: number) => (
                            <div className="row-detalle-horario" style={{ display: "flex" }} key={horario + ind}>
                              <p
                                key={Math.random() + ind}
                                style={{ width: "70%", marginRight: "10px" }}
                                className="font-14"
                              >
                                {horario?.dia.slice(0, 2)} {parseHours(horario.desde)}h a {parseHours(horario.hasta)}h
                              </p>
                              <p className="font-14">{horario.aula ? horario.aula : "Sin asignar"}</p>
                            </div>
                          ))}
                      </div>
                    );
                  }
                })}
            </div>

            <div className="col-profesores">
              {subjectData.subjectsByType &&
                transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
                  const renderProfesor = tipo.titulo === "Profesor";
                  if (renderProfesor) {
                    return (
                      <div className={`ph-horarios-inner-col ph-horarios-inner-col-mobile`} key={Math.random() + index}>
                        <h4 className={`panel-drawer-h4-title`}>Profesor/a</h4>
                        <div className="col-profesores-list">
                          {tipo.detalles.length > 0 ? (
                            tipo.detalles.map((persona: any, i: number) => (
                              <p key={Math.random() + i}>
                                {persona.nombre.includes("null")
                                  ? "A designar"
                                  : mobileFeatures
                                  ? getProfesorLastname(persona.nombre)
                                  : persona.nombre}
                                {persona.rol && !persona.rol.includes("null") && showRol && (
                                  <span className="ph-professor-rol-pill">{persona.rol}</span>
                                )}
                              </p>
                            ))
                          ) : (
                            <p>A designar</p>
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        )}
      </>
    );
  }

  /* ---------------------------- content for card ---------------------------- */
  return (
    <>
      {mobileFeatures ? (
        <div style={{ zIndex: 1 }} className={`w-[75%] space-y-2 h-full py-3`}>
          {subjectData.subjectsByType &&
            transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
              const renderHorarios = !(tipo.titulo === "Profesor");
              const mostrarHorarios = student.mostrarHorarios;

              if (tipo.titulo === null) {
                return <p className="font-semibold text-[#B7B7B7]">⚠ Información faltante.</p>;
              } else if (renderHorarios && mostrarHorarios && renderHorarios !== null) {
                return (
                  <div className={`text-[14px] flex`} key={Math.random() + index}>
                    <h4 className={`text-[#B7B7B7] w-[35%]`}>{tipo.titulo}</h4>
                  <div className="w-[65%]">
                    {tipo.titulo !== null &&
                      tipo.detalles.map((horario: any, ind: number) => (
                        <p key={Math.random() + ind} >
                          {horario?.dia.slice(0, 2)} {parseHours(horario.desde)}h a {parseHours(horario.hasta)}h
                        </p>
                      ))}
                  </div>
                  </div>
                );
              }
            })}
        </div>
      ) : (
        <div
          style={{ zIndex: 1 }}
          className={`content-rows-ph mobile-horarios row-ph ${
            showBorderTop ? "border-top-ph" : ""
          }`}
        >
          {subjectData.subjectsByType &&
            transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
              const renderProfesor = tipo.titulo === "Profesor";
              const renderHorarios = !renderProfesor;
              const mostrarHorarios = student.mostrarHorarios;

              if (tipo.titulo === null) {
                return <p className="font-semibold text-[#B7B7B7]">⚠ Información faltante.</p>;
              } else if (renderProfesor) {
                return (
                  <div
                    className={`ph-horarios-inner-col profesores-first-col hide-profesores-mobile`}
                    key={Math.random() + index}
                  >
                    <h4 className={`ph-h4-title`}>{tipo.titulo === "Profesor" ? "Profesor/a" : tipo.titulo}</h4>
                    <p>{findProfeTitularForCard(subjectData)}</p>
                  </div>
                );
              } else if (renderHorarios && mostrarHorarios && renderHorarios !== null) {
                return (
                  <div className={`ph-horarios-inner-col`} key={Math.random() + index}>
                    <h4 className={`ph-h4-title`}>{tipo.titulo}</h4>
                    <div className="horarios-col-mobile">
                      {tipo.titulo !== null &&
                        tipo.detalles.map((horario: any, ind: number) => (
                          <p key={Math.random() + ind}>
                            {horario?.dia.slice(0, 2)} {parseHours(horario.desde)}h a {parseHours(horario.hasta)}h
                          </p>
                        ))}
                    </div>
                  </div>
                );
              }
            })}
        </div>
      )}
    </>
  );
}

export default ProfessorAndSubjectHours;
