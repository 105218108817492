import { useSelector } from "react-redux";
import { selectFlags } from "../../reduxSlices/globalFlagsSlice";
import "./DesktopTab.css";

interface IPropsTabs {
  handleSelectTab: (tipo: string) => void;
  filtro: string;
}

export default function DesktopTab({ handleSelectTab, filtro }: IPropsTabs) {
  const {isMobileSearchActive} = useSelector(selectFlags);
  return (
    <div className="desktop-subjects-tab min-w-[80px]">
      <p
        className={`desktop-subjects-tab-button ${filtro === "OBLIGATORIO" ? "desktop-tab-active" : ""}`}
        onClick={() => {
          handleSelectTab("OBLIGATORIO");
        }}
      >
        {isMobileSearchActive ? "Obl" : "Obligatorias"}
      </p>
      <p
        className={`desktop-subjects-tab-button ${filtro === "OPTATIVO" ? "desktop-tab-active" : ""}`}
        onClick={() => {
          handleSelectTab("OPTATIVO");
        }}
      >
        {isMobileSearchActive ? "Opt" : "Optativas"}
      </p>
    </div>
  );
}
