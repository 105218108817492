import { useEffect, useState } from "react";
import "./DesktopSubjectItem.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import {
  ICalendarizedSubjects,
  ICalendarSubjects,
  IDesktopCalendarSubjects,
  IOfferedReserveData,
  IOfferedSubjectData,
  IOfferedSubjectSection,
  IQuota,
  ISelectedSection,
} from "../../types";
import { useSelector } from "react-redux";
import { getMateriasFijadasAlumno, toggleMateriaFijadaAlumno } from "../../Api/subject";
import { selectStudent } from "../../reduxSlices/studentSlice";
import {
  addCalendarSubject,
  removeCalendarSubject,
  selectCalendarSubjectsList,
  selectSubcriptionProcessSectionIds,
  setDetailSubjectOffered,
  setPinnedSubjectsList,
  selectFailedSubcriptionIds,
} from "../../reduxSlices/subjectsSlice";
import { useAppDispatch } from "../../app/store";
import {
  selectFlags,
  selectInTransitInRquest,
  selectMobileFeatures,
  setDesktopTab,
  setLastCalendarAdd,
  setPanelFilters,
} from "../../reduxSlices/globalFlagsSlice";
import ProfessorAndSubjectHours from "../ProfessorAndSubjectHours/ProfessorAndSubjectHours";
import { useNavigate } from "react-router-dom";
import QuotaCountIcon from "../QuotaCountIcon/QuotaCountIcon";
import ButtonInscription from "../ButtonInscription/ButtonInscription";
import { findProfeTitular } from "../../utils";
import ButtonPin from "../ButtonPin/ButtonPin";
import ButtonCalendarize from "../ButtonCalendarize/ButtonCalendarize";

interface IPropsSubjectItem {
  id: number;
  subjectId: number;
  section: string;
  subjectData: IOfferedSubjectData;
  quota: IQuota;
  onChange?: (data: ISelectedSection) => Promise<any>;
  pinned: boolean;
  creditos: number;
  sectionType: string;
  idMateria: number;
  turno: string;
  subject: IOfferedSubjectSection;
}

const filtrarCasosRepetidos = (cursos: any[]) => {
  let uniqueCourses: any = [];
  cursos.forEach((curso: any) => {
    const existe = uniqueCourses.some(
      (uniqueCurso: any) => uniqueCurso.dia === curso.dia && uniqueCurso.desde === curso.desde
    );
    if (!existe) {
      uniqueCourses.push(curso);
    }
  });
  return uniqueCourses;
};

function extraerSeccion(texto: string) {
  const regex = /- (S\d+)/;
  const match = texto.match(regex);
  return match ? match[1] : null;
}
const extraerNombreMateria = (texto: string) => {
  return texto.split(" - ")[0] || "texto";
};

const DesktopSubjectItem = ({
  section,
  id,
  subjectData,
  quota,
  onChange,
  subjectId,
  pinned,
  creditos,
  sectionType,
  idMateria,
  turno,
  subject,
}: IPropsSubjectItem) => {
  const student = useSelector(selectStudent);
  const calendarSubsList = useSelector(selectCalendarSubjectsList);
  const inTransitInscriptionRequet = useSelector(selectInTransitInRquest);
  const mobileFeatures = useSelector(selectMobileFeatures);
  const { collapseCards, collapseCardsSinCupo } = useSelector(selectFlags);
  const dispatch = useAppDispatch();
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [hasClick, setHasClick] = useState(false);
  const [inscriptionConfirmed, setInscriptionConfirmed] = useState(false);
  const subcriptionProcessSectionIds = useSelector(selectSubcriptionProcessSectionIds);
  const failedSubcriptionIds = useSelector(selectFailedSubcriptionIds);
  const [failedInscription, setFailedInscription] = useState(false);
  const [isSubInProcess, setIsSubInProcess] = useState(false);

  const navigate = useNavigate();

  const subType = sectionType === "OPTATIVO" ? "Optativa" : "Obligatoria";

  const gradientClass = () => {
    if (quota && quota.availability > 0 && student.prioridad && pinned) {
      return "bg-gradient-to-t from-[#ECF4F4] to-transparent";
    } else if (quota && quota.availability === 0 && student.prioridad) {
      return "bg-gradient-to-t from-[#F2F2F2] to-transparent";
    } else {
      return "bg-gradient-to-t from-[#ffffff] to-transparent";
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                   EFFECTS                                  */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (inTransitInscriptionRequet && hasClick) {
      setTimeout(() => {
        setHasClick(false);
      }, 10000);
    }
  }, [inTransitInscriptionRequet]);

  /* ------------------- Failed in validation response CLOUD ------------------ */
  useEffect(() => {
    if (failedInscription) {
      setTimeout(() => {
        setFailedInscription(false);
      }, 500);
    }
  }, [failedInscription]);
  /* ---------------------- Failed in response Web Socket --------------------- */
  useEffect(() => {
    if (failedSubcriptionIds.indexOf(Number(id)) !== -1) {
      setFailedInscription(true);
    }
  }, [failedSubcriptionIds]);

  useEffect(() => {
    const isProcessing = subcriptionProcessSectionIds.indexOf(Number(id)) !== -1;
    if (isProcessing) {
      setIsSubInProcess(true);
    } else {
      setIsSubInProcess(false);
    }
  }, [subcriptionProcessSectionIds]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleModalPanelInfo = (subjectId: number, cursoId: number) => {
    dispatch(setDetailSubjectOffered({ subjectId, cursoId }));
    dispatch(setPanelFilters(false));
  };

  return (
    <div className={`desktop-subject-item-container`}>
      <div
        className={`desktop-subject-item ${pinned ? "desktop-pinned-subject" : ""} ${
          quota && quota.availability === 0 && student.prioridad ? "bg-gray-noquota" : ""
        } ${inscriptionConfirmed ? "hidden-card" : ""}`}
      >
        <div className={`animation-wrapper ${failedInscription ? "cancel-animation" : ""}`}>
          <div className={`${isSubInProcess ? "fill-animation" : " "}`}></div>
          <div style={{ zIndex: 1 }} className="desktop-item-first-row">
            {quota && quota?.availability >= 0 && student.prioridad && (
              <>
                <QuotaCountIcon
                  qty={quota.qty}
                  subjectData={subjectData}
                  availability={quota.availability}
                  idMateria={idMateria}
                  quota={quota}
                />
              </>
            )}
            <div className="title-column-card w-3/6">
              {mobileFeatures ? (
                <div
                  className={`flex flex-row hover:underline hover:cursor-pointer ${
                    quota && quota.availability === 0 && student.prioridad ? "text-gray" : ""
                  }`}
                  onClick={() => handleModalPanelInfo(Number(subjectId), Number(id))}
                >
                  <p
                    title={`${extraerNombreMateria(section)}`}
                    className={`desktop-item-title desktop-item-section max-w-fit`}
                  >
                    {`${extraerNombreMateria(section)} `}
                  </p>
                  <p className={`desktop-item-title max-w-fit`}>&nbsp;{`${extraerSeccion(section)}`}</p>
                </div>
              ) : (
                <div
                  className={`flex flex-row w-auto hover:underline hover:cursor-pointer ${
                    quota && quota.availability === 0 && student.prioridad ? "text-gray" : ""
                  }`}
                  onClick={() => handleModalPanelInfo(Number(subjectId), Number(id))}
                >
                  <p title={`${extraerNombreMateria(section)}`} className={`desktop-item-title desktop-item-section`}>
                    {`${extraerNombreMateria(section)}`}
                  </p>
                  <p className={`desktop-item-title min-w-fit`}>&nbsp;{`${extraerSeccion(section)}`}</p>
                </div>
              )}
              <p className="mobile-profesor-titular">{findProfeTitular(subjectData) || "A designar"}</p>
            </div>
            <div className="desktop-items-actions">
              <div className="item-actions-circles">
                {pinned && <p className="subject-item-type">{subType}</p>}
                <ButtonPin subject={subject} />
                <ButtonCalendarize subject={subject} />
              </div>
              <ButtonInscription id={id} idMateria={idMateria} subjectData={subjectData} />
            </div>
          </div>
          <div
            className={`${
              collapseCards
                ? "hide-collapse-info"
                : collapseCardsSinCupo && quota && quota.availability === 0
                ? "hide-collapse-info"
                : ""
            } bottom-car-section flex-grow`}
          >
            <div className="divider-card"></div>
            {/* ------------------------ horarios desktop ----------------------- */}
            {!mobileFeatures && (
              <div className={`desktop-item-second-row ${mobileFeatures ? "max-h-28 overflow-y-auto" : ""}`}>
                <ProfessorAndSubjectHours subjectData={subjectData} isDrawerContent={false} />
                {turno ? (
                  <div className="desktop-inner-col" style={{ width: "100px", zIndex: "1" }}>
                    <h4 className="ph-h4-title">Turno</h4>
                    <p>{turno}</p>
                  </div>
                ) : (
                  <div className="desktop-inner-col"></div>
                )}
                {creditos > 0 ? (
                  <div className="desktop-inner-col" style={{ zIndex: "1" }}>
                    <h4 className="ph-h4-title">Créditos</h4>
                    <p>{creditos}</p>
                  </div>
                ) : (
                  <div className="desktop-inner-col"></div>
                )}
              </div>
            )}
            {/* ----------------------------- horarios mobile ---------------------------- */}
            {mobileFeatures && (
              <div className={`flex-grow max-h-24 overflow-y-auto flex items-center gap-2 relative`}>
                {/* <div className={`sticky border-2 w-full h-4 z-10 ${gradientClass()}} bottom-0`}></div> */}
                <ProfessorAndSubjectHours subjectData={subjectData} isDrawerContent={false} />
                {turno || creditos ? <div className="bg-[#eeeeee] w-[1px] h-[85%]"></div> : ""}
                <div className="w-[25%] space-y-2 py-3 h-full">
                  {turno && (
                    <div className="" style={{ zIndex: "1" }}>
                      <h4 className="text-[#B7B7B7] text-[14px]">Turno</h4>
                      <p className="text-[14px]">{turno}</p>
                    </div>
                  )}
                  {creditos > 0 && (
                    <div className="" style={{ zIndex: "1" }}>
                      <h4 className="text-[#B7B7B7] text-[14px]">Créditos</h4>
                      <p className="text-[14px]">{creditos}</p>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* ------------------------------------ . ----------------------------------- */}
          </div>

          {mobileFeatures && (
            <div className="z-[1] flex items-center justify-end gap-[8px]">
              {pinned && <p className="subject-item-type">{subType}</p>}
              <ButtonPin subject={subject} />
              <ButtonCalendarize subject={subject} />
              <ButtonInscription id={id} idMateria={idMateria} subjectData={subjectData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DesktopSubjectItem;
