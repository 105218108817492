import { ICalendarSubjects, IDesktopCalendarSubjects, ISubjectColors } from "../types";

export const getInitialHour = (data: any[]) => {
  return data.reduce((acc, e) => {
    return e.desde < acc ? e.desde : acc;
  }, Infinity);
};

export const getFinalHour = (data: any[]) => {
  return data.reduce((acc, e) => {
    return e.hasta > acc ? e.hasta : acc;
  }, 0);
};

export const getRowIndex = (time: number, initialTime: number) => {
  const hour = Math.floor(time / 100);
  const minute = time % 100;
  const initialHour = Math.floor(initialTime / 100);
  const totalMinutes = (hour - initialHour) * 60 + minute;
  return totalMinutes / 5 + 1;
};

export const getGridColumn = (dia: string) => {
  switch (dia) {
    case "Lunes":
      return 2;
    case "Martes":
      return 3;
    case "Miércoles":
      return 4;
    case "Jueves":
      return 5;
    case "Viernes":
      return 6;
    default:
      return 2;
  }
};

const colors: ISubjectColors[] = [
  { background: "#2277DA", border: "#2277DA" },
  { background: "#FF4789", border: "#FF4789" },
  { background: "#FF7A30", border: "#FF7A30" },
  { background: "#FBC54F", border: "#FBC54F" },
  { background: "#97D86C", border: "#97D86C" },
  { background: "#2ED4C6", border: "#2ED4C6" },
  { background: "#C47DDD", border: "#C47DDD" },
];

const colorMap = new Map();

export const assignColorToSubject = (idcurso: number) => {
  if (!colorMap.has(idcurso)) {
    const colorIndex = colorMap.size % colors.length;
    colorMap.set(idcurso, colors[colorIndex]);
  }

  return colorMap.get(idcurso);
};

export const checkIfTimeOverlaps = (initial1: number, end1: number, initial2: number, end2: number) => {
  // return initial1 < end2 && initial2 < end1
  return (
    (initial1 >= initial2 && initial1 < end2) ||
    (initial2 > initial2 && initial2 <= end2) ||
    (initial1 <= initial2 && initial2 >= end2)
  );
};

const generateCourseDictionary = (subjects: IDesktopCalendarSubjects[]) => {
  const courseDictionary: Record<number, number> = {};
  let currentNumber = 0;

  subjects.forEach((subject) => {
    if (!(subject.idCurso in courseDictionary)) {
      courseDictionary[subject.idCurso] = currentNumber;
      currentNumber++;
    }
  });

  return courseDictionary;
};

function calcularDuracionEnMinutos(horaInicio: number, horaFin: number): number {
  // Obtener las horas y los minutos de la hora de inicio
  const horasInicio = Math.floor(horaInicio / 100);
  const minutosInicio = horaInicio % 100;

  // Obtener las horas y los minutos de la hora de fin
  const horasFin = Math.floor(horaFin / 100);
  const minutosFin = horaFin % 100;

  // Convertir todo a minutos
  const inicioEnMinutos = horasInicio * 60 + minutosInicio;
  const finEnMinutos = horasFin * 60 + minutosFin;

  // Calcular la diferencia
  const duracionEnMinutos = finEnMinutos - inicioEnMinutos;
  return duracionEnMinutos;
}

function asignarSuperposiciones(cursos: ICalendarSubjects[]): ICalendarSubjects[] {
  // 1. Ordenar los cursos por duración (descendente) y luego por tiempo de inicio (ascendente)
  //@ts-ignore
  // cursos.sort((a, b) => b.duracion - a.duracion || a.desde - b.desde);

  // 2. Iterar y asignar la propiedad "superpuesta"
  const resultado: ICalendarSubjects[] = [];

  for (let i = 0; i < cursos.length; i++) {
    let superpuestaActual = 0;

    // Verificar superposiciones con los cursos ya procesados
    for (let j = 0; j < resultado.length; j++) {
      const cursoProcesado = resultado[j];

      if (
        (cursos[i].desde >= cursoProcesado.desde && cursos[i].desde < cursoProcesado.hasta) ||
        (cursos[i].hasta > cursoProcesado.desde && cursos[i].hasta <= cursoProcesado.hasta)
      ) {
        // Si hay superposición, actualizar el valor de "superpuesta"
        superpuestaActual = Math.max(superpuestaActual, cursoProcesado.superpuesta + 1);
      }
    }

    // Asignar el valor calculado
    cursos[i].superpuesta = superpuestaActual;
    resultado.push(cursos[i]); // Agregar el curso al resultado
  }

  return resultado;
}

export function asignacionPropiedadesParseoCursos(cursos: ICalendarSubjects[]): ICalendarSubjects[] {
  console.log(cursos);
  
  /* -------------------------------------------------------------------------- */
  /*                               Setear COLORES                               */
  /* -------------------------------------------------------------------------- */
  const colors = [
    "#FF4789",
    "#FF7A30",
    "#FBC54F",
    "#97D86C",
    "#209DAE",
    "#2277DA",
    "#C47DDD",
    "#FF4789",
    "#FF7A30",
    "#FBC54F",
    "#97D86C",
    "#209DAE",
    "#2277DA",
    "#C47DDD",
  ];
  const diccionariocolors = generateCourseDictionary(cursos);
  cursos.map((item) => {
    const color = colors[diccionariocolors[item.idCurso]];
    item.color = color;
  });

  /* ----------------------------- Agrupar por DIA ---------------------------- */
  const agrupadosPorDia: { [key: string]: ICalendarSubjects[] } = cursos.reduce(
    (acumulador: any, curso: ICalendarSubjects) => {
      const diaNormalizado = curso.dia.trim().toLowerCase();
      if (!acumulador[diaNormalizado]) {
        acumulador[diaNormalizado] = [];
      }
      acumulador[diaNormalizado].push(curso);
      return acumulador;
    },
    {}
  );
  /* -------------------------------------------------------------------------- */
  /*                         Recorrer por DIA y PARSEAR                         */
  /* -------------------------------------------------------------------------- */
  let cursosParseados: ICalendarSubjects[] = [];
  Object.values(agrupadosPorDia).forEach((grupo: ICalendarSubjects[]) => {
    // Agregamos la propiedad Duracion
    grupo.forEach((curso) => (curso.duracion = calcularDuracionEnMinutos(curso.desde, curso.hasta)));
    // Ordernar por calcularDuracionEnMinutos 
    // @ts-ignore
    grupo.sort((a: ICalendarSubjects, b: ICalendarSubjects) => b.duracion - a.duracion);

    const asignados:ICalendarSubjects[] = asignarSuperposiciones(grupo)
    // asignando warning para icono
    asignados.forEach(curso=>{
      if(curso.superpuesta>0){
        curso.warning=true
      }
    })
    cursosParseados.push(...asignados)
  });

  return cursosParseados;
}
